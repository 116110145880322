import { PortalModule } from "@angular/cdk/portal";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_RIPPLE_GLOBAL_OPTIONS } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { EditorModule } from "@tinymce/tinymce-angular";
import { InlineSVGModule } from "ng-inline-svg-2";
import { QuillModule } from "ngx-quill";
import { ToastrModule } from "ngx-toastr";
import { SwiperModule } from "swiper/angular";
import "quill-mention/autoregister";

import { GptCommandDialogComponent } from "@app/core/components/gpt-command-dialog/gpt-command-dialog.component";
import { GptResponseDialogComponent } from "@app/core/components/gpt-response-dialog/gpt-response-dialog.component";
import { CoreModule } from "@app/core/core.module";
import { JwtInterceptor } from "@app/shared/helpers/jwt.interceptor";
import { RoamToggleSliderComponent } from "@shared-components/button/roam-toggle-slider/roam-toggle-slider.component";
import { ErrorInterceptor } from "@shared/helpers/error.interceptor";
import { SharedModule } from "@shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthPopupModule } from '@shared/dialogs/auth-popup/auth-popup.module';
import { NgxStripeModule } from "ngx-stripe";
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { environment } from "@environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    GptCommandDialogComponent,
    GptResponseDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AuthPopupModule,
    CoreModule,
    EditorModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    MatChipsModule,
    MatSidenavModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: "df261b06-76ef-4936-bd67-4c57af055858",
          authority:
            "https://login.microsoftonline.com/a0406172-9251-4b4b-9e0b-bdeaf3bdce16/oauth2/v2.0/authorize",
          redirectUri: "/auth",
          knownAuthorities: ["login.microsoftonline.com"], // Specify known authorities for AAD
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false, // set to true for IE 11
        },
        system: {
          loggerOptions: {
            loggerCallback: () => {},
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ["offline_access", "user.read", "mail.read", "calendars.read", "tasks.read"]
        }
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["offline_access", "user.read", "mail.read", "calendars.read", "tasks.read"]],
        ]),
      }
    ),
    PortalModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    RoamToggleSliderComponent,
    SharedModule,
    SwiperModule,
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideFirebaseApp(() => initializeApp({
      projectId: environment.firebase.projectId,
      appId: environment.firebase.appId,
      apiKey: environment.firebase.apiKey,
      authDomain: environment.firebase.authDomain, 
      messagingSenderId: environment.firebase.messaging.messagingSenderId
    })),
    provideMessaging(() => getMessaging()),
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
