import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssociationTabsComponent } from "@app/pages/associations/page/association-tabs/association-tabs.component";
import { ErrorRoutingModule } from "@shared/error-routing/error-routing.module";
import { UncaughtErrorComponent } from "@shared/error-routing/error/uncaught-error.component";
import { PageNotFoundComponent } from "@shared/error-routing/not-found/not-found.component";
import {
  authGuard,
  canMatchNotAuthenticated,
} from "@shared/services/guard/auth.guard";
import {
  canMatchHasModuleAccess,
  canMatchRole,
  canMatchUserConfigHasLoaded,
} from '@core/access-control/+data-access';
import { canMatchRoleVendor } from "./pages/vendor/+date-access";
import { APP_ROUTES } from "./utils/routes";
import { OAuthCallbackComponent } from "./pages/oauth-callback/oauth-callback.component";

export const routes: Routes = [
  {
    path: APP_ROUTES.VERIFY_LOGIN,
    loadComponent: async () => {
      const factory = await import("./pages/verify-login/verify-login.page");
      return factory.VerifyLoginPage;
    },
  },
  {
    path: APP_ROUTES.BASE,
    canMatch: [canMatchNotAuthenticated],
    loadChildren: () =>
      import("./pages/home-page/home-page.module").then(m => m.HomePageModule),
    data: { text: "HomePage" },
  },
  {
    path: APP_ROUTES.BASE,
    canMatch: [authGuard, canMatchUserConfigHasLoaded],
    loadComponent: () =>
      import("./pages/main/main.component").then(x => x.MainComponent),
    children: [
      {
        path: APP_ROUTES.COMMUNITY,
        loadChildren: () =>
          import("./pages/community/community.module").then(
            m => m.CommunityModule
          ),
        data: { text: "Community" },
      },
      {
        path: APP_ROUTES.OVERVIEW,
        canMatch: [canMatchRole("member"), canMatchHasModuleAccess("overview")],
        loadComponent: async () => {
          const factory = await import("./pages/vendor/+pages");
          return factory.VendorOverviewPage;
        },
      },
      {
        path: APP_ROUTES.OVERVIEW,
        data: { text: "Dashboard" },
        canMatch: [
          canMatchRole(["manager", "employee"]),
          canMatchHasModuleAccess("overview"),
        ],
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            m => m.DashboardModule
          ),
      },
      {
        path: APP_ROUTES.JOBS,
        canMatch: [canMatchRoleVendor],
        loadChildren: async () =>
          (await import("./pages/job/+pages")).jobsRoutes,
      },
      {
        path: APP_ROUTES.USERS,
        data: { text: "Users" },
        loadChildren: () =>
          import("./pages/users/users.module").then(m => m.UsersModule),
      },
      {
        path: APP_ROUTES.LISTINGS,
        loadChildren: () =>
          import("./pages/listings/listings.module").then(
            m => m.ListingsModule
          ),
        data: { text: "Listings" },
      },
      {
        path: APP_ROUTES.CALENDAR,
        loadChildren: () =>
          import("./pages/calendar/calendar.module").then(
            m => m.CalendarModule
          ),
        data: { text: "Calendar" },
      },
      {
        path: APP_ROUTES.DISCOVER,
        loadChildren: () =>
          import("./pages/discover/discover.module").then(
            m => m.DiscoverModule
          ),
        data: { text: "Discover" },
      },
      {
        path: APP_ROUTES.RESERVATIONS,
        loadChildren: () =>
          import("./pages/reservations/reservations.module").then(
            m => m.ReservationsModule
          ),
        data: { text: "Reservations" },
      },
      {
        path: APP_ROUTES.INBOX,
        loadChildren: () =>
          import("./pages/inbox/inbox.module").then(m => m.InboxModule),
        data: { text: "Inbox" },
      },
      {
        path: APP_ROUTES.TASKS,
        loadChildren: () =>
          import("./pages/task/task.routes").then(m => m.taskRoutes),
        data: { text: "Tasks" },
      },
      {
        path: APP_ROUTES.DASHBOARD_HOME,
        data: { text: "Home" },
        canMatch: [canMatchRole(["employee", "owner"])],
        loadChildren: () =>
          import("./pages/dashboard-home/dashboard-home.module").then(
            m => m.DashboardHomeModule
          ),
      },
      {
        path: APP_ROUTES.PAYMENTS,
        loadChildren: () =>
          import("./pages/payments/payments.module").then(
            m => m.PaymentsModule
          ),
        data: { text: "Payments" },
      },
      {
        path: APP_ROUTES.MESSAGES,
        loadChildren: () =>
          import("./pages/messages/messages.module").then(
            m => m.MessagesModule
          ),
        data: { text: "Messages" },
      },
      {
        path: APP_ROUTES.MARKETPLACE,
        loadChildren: () =>
          import("./pages/marketplace/marketplace.module").then(
            m => m.MarketplaceModule
          ),
        data: { text: "For Sale" },
      },
      {
        path: APP_ROUTES.INSURANCE,
        loadChildren: () =>
          import("./pages/insurance/insurance.module").then(
            m => m.InsuranceModule
          ),
        data: { text: "Insurance" },
      },
      {
        path: APP_ROUTES.VIOLATIONS, // TODO: maybe change module name
        canMatch: [canMatchRole("employee")],
        canActivateChild: [canMatchRole("employee")],
        loadChildren: () =>
          import("@app/pages/violation/+pages").then(m => m.violationRoutes),
      },
      {
        path: APP_ROUTES.VIOLATIONS,
        loadChildren: () =>
          import("./pages/violation/violations.module").then(
            m => m.ViolationsModule
          ),
        data: { text: "Violations" },
      },
      {
        path: APP_ROUTES.ANNOUNCEMENTS,
        loadChildren: () =>
          import("./pages/announcements/announcements.module").then(
            m => m.AnnouncementsModule
          ),
        data: { text: "Announcements" },
      },
      {
        path: APP_ROUTES.MODIFICATIONS,
        loadChildren: () =>
          import("./pages/modifications/modifications.module").then(
            m => m.ModificationsModule
          ),
        data: { text: "ARC Requests" },
      },
      {
        path: APP_ROUTES.DOCUMENT_CENTER,
        loadChildren: () =>
          import("./pages/document-center/document-center.module").then(
            m => m.DocumentCenterModule
          ),
        data: { text: "DocumentsCenter" },
      },
      {
        path: APP_ROUTES.ACCOUNT_PROFILE,
        loadChildren: () =>
          import("./pages/account-profile/account-profile.module").then(
            m => m.AccountProfileModule
          ),
        data: { text: "AccountProfile" },
      },
      {
        path: APP_ROUTES.FINANCIALS,
        loadChildren: () =>
          import("./pages/financials/financials.module").then(
            m => m.FinancialsModule
          ),
        data: { text: "Financials" },
      },
      {
        path: APP_ROUTES.REPORTING,
        loadChildren: () =>
          import("./pages/reporting/reporting.module").then(
            m => m.ReportingModule
          ),
        data: { text: "Reporting" },
      },
      {
        path: APP_ROUTES.SETTINGS,
        loadChildren: () =>
          import("./pages/system-settings/system-settings.module").then(
            m => m.SystemSettingsModule
          ),
      },
      {
        path: APP_ROUTES.SETTINGS_GLOBAL,
        loadChildren: async () => {
          return (
            await import("./pages/settings-global/settings-global.routes")
          ).settingsGlobalRoutes;
        },
      },
      {
        path: APP_ROUTES.CONTACTS,
        data: { text: "Contacts" },
        canMatch: [canMatchRoleVendor],
        loadChildren: () =>
          import("./pages/contacts/vendor-contacts.routes").then(
            m => m.vendorContactRoutes
          ),
      },
      {
        path: APP_ROUTES.CONTACTS,
        loadChildren: () =>
          import("./pages/contacts/contacts.module").then(
            m => m.ContactsModule
          ),
        data: { text: "Contacts" },
      },
      {
        path: APP_ROUTES.PROPERTIES,
        data: { text: "Properties" },
        canMatch: [
          canMatchRole("member"),
          canMatchHasModuleAccess("properties"),
        ],
        loadChildren: () =>
          import("./pages/property/property.routes").then(
            m => m.propertyRoutes
          ),
      },
      {
        path: APP_ROUTES.TIMESHEET,
        data: { text: "Timesheet" },
        canMatch: [canMatchRoleVendor],
        loadChildren: () =>
          import("./pages/timesheet/timesheet.routes").then(
            m => m.timesheetRoutes
          ),
      },
      {
        path: APP_ROUTES.ASSOCIATIONS,
        loadChildren: () =>
          import("./pages/associations/associations.module").then(
            m => m.AssociationsModule
          ),
      },
      {
        path: APP_ROUTES.SEARCH,
        loadChildren: () =>
          import("./pages/search/search.module").then(m => m.SearchModule),
      },
      {
        path: "associations/detail/:slug",
        component: AssociationTabsComponent,
      },
      {
        path: APP_ROUTES.MAINTENANCE,
        loadChildren: () =>
          import("@app/pages/maintenance/maintenance.module").then(
            m => m.MaintenanceModule
          ),
      },
      {
        path: APP_ROUTES.LEASE_MANAGEMENT,
        loadChildren: () =>
          import("@app/pages/lease-management/lease-management.module").then(
            m => m.LeaseManagementModule
          ),
      },
      // {
      //   path: APP_ROUTES.BOARD_PORTAL,
      //   path: "test",
      //   loadChildren: () =>
      //     import("@app/pages/board-portal/board-portal.module").then(
      //       m => m.BoardPortalModule
      //     ),
      // },
      {
        path: APP_ROUTES.BOARD_PORTAL,
        canMatch: [
          canMatchRole("owner"),
          canMatchHasModuleAccess("board-portal"),
        ],
        loadChildren: () =>
          import(
            "@app/pages/board-portal/board-portal-for-officer.routes"
          ).then(m => m.boardPortalForOfficerRoutes),
      },
      {
        path: APP_ROUTES.BUDGETS,
        canMatch: [canMatchRole("employee")],
        loadChildren: () =>
          import("@app/pages/budget/+pages/budget.routes").then(
            m => m.budgetRoutes
          ),
      },
      {
        path: "",
        redirectTo: APP_ROUTES.OVERVIEW,
        canMatch: [canMatchHasModuleAccess("overview")],
        pathMatch: "full",
      },
      {
        path: "",
        redirectTo: APP_ROUTES.DASHBOARD_HOME,
        canMatch: [canMatchHasModuleAccess("home")],
        pathMatch: "full",
      },
      {
        path: APP_ROUTES.WORK_ORDERS,
        loadChildren: () =>
          import(
            "@app/pages/work-order-resident/work-order-resident.module"
          ).then(m => m.WorkOrderResidentModule),
      },
      {
        path: APP_ROUTES.COMMUNICATION,
        loadChildren: () =>
          import("@app/pages/communication/communication.routes").then(
            m => m.communicationRoutes
          ),
      },
    ],
  },
  {
    path: APP_ROUTES.RENTAL_APPLICATION,
    loadChildren: () =>
      import(
        "@app/pages/resident-portal/rental-application/rental-application.module"
      ).then(m => m.RentalApplicationModule),
  },
  {
    path: APP_ROUTES.OAUTH_CALLBACK,
    children: [
      { path: "callback", component: OAuthCallbackComponent },
    ],
  },
  {
    path: APP_ROUTES.ERROR,
    component: UncaughtErrorComponent,
  },
  {
    path: "playground",
    loadComponent: async () => {
      const factory = await import("./pages/_playground/playground.page");
      return factory.PlaygroundPage;
    },
  },
  { path: "**", component: PageNotFoundComponent }, // must always be last
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "top",
      bindToComponentInputs: true,
    }),
    ErrorRoutingModule,
  ],
  exports: [RouterModule, ErrorRoutingModule],
})
export class AppRoutingModule {}
